<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
<div>
  <div class="lockscreen-logo">
    <a href="/soal-mapel">LOGO TARUNA</a>
  </div>
  <!-- User name -->
  <div class="text-center"><h4>IMG</h4></div>
  <br>
  <div class="text-center"><h6>NAMA SISWA</h6></div>
  

  <br>
  <br>
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
  <div class="text-center">
    <p>Kamu akan segera memulai soal latihan menggunakan e-Learning Taruna. Sistem akan merecord hasil latihan kamu. Silahkan dipersiapkan alat tulis jika diperlukan.
    Setelah mulai mengerjakan, maka counter waktu akan terus berjalan dan tidak dapat dihentikan hingga hasil latihan kamu dikirim.
    Hasil latihan kamu tetap aman terjaga privasinya, dan akan menjadi bahan evaluasi bagi LBB untuk pengambilan kebijakan di kemudian hari.</p>
  </div>
        </div>
        <div class="col-2"></div>
    </div>
  <div class="text-center">
    <a href="/soal-process" class="btn btn-lg btn-primary"><i class="fas fa-arrow-right"></i> Mulai Latihan</a>
  </div>  
</div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
    name: 'SoalStart',
    data() {
        return {
            
        };
    }
}
</script>
<style type="text/css" scoped>
</style>